<template>
  <b-row no-gutters>
    <b-col>
      {{ formattedDays }}
    </b-col>
  </b-row>
</template>
<script>
import { getShortWeekDayFromIndex } from "@/utils/form";

export default {
  props: {
    days: {
      type: Array,
      default: [],
    }
  },
  data: () => ({
    formattedDays: '',
  }),
  mounted () {
    this.formattedDays = this.getShortDaysFormat();
  },
  methods: {
    getShortDaysFormat () {
      if (this.days.length > 0 ) {

        for (const i in this.days) {
          this.days[i] = parseInt(this.days[i]);
        }

        this.days.sort((a, b) => a - b);

        let successor = true;

        if (this.days.length > 1) {
          let i = 0;
          while( i < this.days.length - 1) {
            if (this.days[i] + 1 === this.days[i + 1]) {
              i++;
            } else {
              successor = false;
              i++;
              break;
            }
          }
        }

        if (successor && this.days.length > 1) {
          return (this.$t(getShortWeekDayFromIndex(this.days[0]) )+ ' - ' + this.$t(getShortWeekDayFromIndex(this.days[this.days.length - 1])));
        } else if (successor) {
          return (this.$t(getShortWeekDayFromIndex(this.days[0])));
        } else {
          let string = '';
          for (const i in this.days) {
            if(parseInt(i) ===  parseInt(this.days.length-1)) {
              string += this.$t(getShortWeekDayFromIndex(this.days[i]));
            } else {
              string += this.$t(getShortWeekDayFromIndex(this.days[i])) + ', ';
            }
          }
          return string;
        }
      } else {
        return this.$t('general.actions.undefined');
      }
    }
  }
}
</script>